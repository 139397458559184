.view-doc{
    background-color:#1E6F5C;
    margin: 5px;
    padding: 8px;
    border-radius:8px;
    border-style: solid;
    border-color:  #289672;
    cursor: pointer;
   
}

.view-doc p {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 12px !important;
    
}